// filename: src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';
import { useAuthStore } from "@/stores/auth"; // authService is a centralized auth service

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Set the document title based on the current route
  document.title = to.meta.title || 'Tak Żyli';

  const authStore = useAuthStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authStore.userToken) {
      authStore.logout();
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

// ~/src/stores/authStore.js
import { defineStore } from 'pinia';

import axiosInstance from "@/interceptors/axios.config";

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        userToken: localStorage.getItem('userToken') || null,
        isLoggedIn: !!localStorage.getItem('userToken'),
        hasAcceptedLatestTerms: false,
    }),
    actions: {
        login(credentials) {
            return new Promise((resolve, reject) => {
                const form = new FormData();
                form.append('username', credentials.username);
                form.append('password', credentials.password);

                axiosInstance.post('/login/', form)
                    .then((response) => {
                        this.userToken = response.data.user_token;
                        this.hasAcceptedLatestTerms = response.data.newest_regulamin_accepted;
                        this.isLoggedIn = true;

                        localStorage.setItem('userToken', this.userToken);

                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.isLoggedIn = false;
                        reject(error);
                    });
            });
        },
        async refreshAccessToken() {
            try {
                await axiosInstance.post('/refresh');
            } catch (error) {
                // Handle refresh token error
            }
        },
        logout() {
            this.userToken = null;
            this.isLoggedIn = false;
            localStorage.removeItem('userToken');
        },
    },
});

import {createApp} from 'vue';
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import VueCookies from 'vue3-cookies';
import { createPinia } from 'pinia';
import '@mdi/font/css/materialdesignicons.css';
import 'swiper/css/bundle';

import App from './App.vue';
import router from './router';

const vuetify = createVuetify({
  components,
  directives,
});
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueCookies);
app.use(vuetify).mount('#app');

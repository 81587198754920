import axiosInstance from '@/interceptors/axios.config';

const FormData = require('form-data');

export const uploadImage = (file, categoryName) => {
  const apiUrl = process.env.VUE_APP_API_URL
  const slotNumber = sessionStorage.getItem('slotNumber');

  if (!slotNumber) {
    return;
  }

  const url = `${apiUrl}media/images/${slotNumber}`;
  const form = new FormData();

  form.append('image', file);
  form.append('category_name', categoryName);

  const config = {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.post(url, form, config)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const deleteImage = (photoUrl) => {
  const slotNumber = sessionStorage.getItem('slotNumber');

  if (!slotNumber || !photoUrl) {
    return new Promise((resolve, reject) => reject('Invalid parameters'))
  }

  const apiUrl = process.env.VUE_APP_API_URL;
  const hash = extractHashFromUrl(photoUrl)
  const url = `${apiUrl}media/images/${slotNumber}/${hash}`;

  const config = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.delete(url, config)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
      throw error;
    });
};

const extractHashFromUrl = (url) => {
  if (!url) {
    return;
  }

  const parts = url.split('_');
  const hashWithExtension = parts[parts.length - 1];

  if (!hashWithExtension) {
    return;
  }

  return hashWithExtension.split('.')[0];
};
